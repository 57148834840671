<template>
  <div>
    <b-input-group>
      <span
        v-show="fileData.uploadedFileNames.length > 0"
        class="mb-2 fileName">
        ファイル名：<span v-for="(fileName, index) in fileData.uploadedFileNames" :key="index">
          <span v-if="index !== 0">, </span>
          <b-link class="link-file-name" @click="downloadApploadedFile(fileData.uploadedFileKey ,index, fileName)">{{fileName}}</b-link>
        <img
          src="@/assets/svg/close_small.svg"
          @click="kickDeleteFile(index)"
          class="tdDeleteBtn">
      </span>
      </span>
      <span
        v-show="fileData.uploadedFileNames.length === 0"
        class="mb-2 fileName">
        ファイル名：ファイルが選択されていません。
      </span>
    </b-input-group>
    <b-input-group>
      <div
        @dragover.prevent="toggleDrag($event, true)"
        @dragleave.prevent="toggleDrag($event, false)"
        @drop.prevent="onDrop($event)"
        :class="[
          isDragging ? 'is-dragging' : '',
          hasError ? 'errItem' : '',
          'drop', 'flex', 'flexMiddle', 'flexCenter'
        ]">
        <span>
          <span
            :class="[
            isDragging ? 'is-dragging' : '',
            'dragText', 'fw-6'
            ]">ここにファイルをドラッグ＆ドロップ または</span>
          <label
            v-if="!noInput"
            :for="fileKey"
            class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
          <label
            v-if="noInput"
            class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
          <p
            :class="[
              isDragging ? 'is-dragging' : '',
              'fileNotation', 'mb-0'
            ]">
            （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます）
            </p>
          <input type="file" style="display:none;"
            :id="fileKey"
            @change="upload($event)"
            multiple
          >
        </span>
      </div>

    </b-input-group>
    <b-input-group>
      <!-- todo:複数対応 -->
      <span
        v-show="showRegedFileList"
        class="mb-2 fileName">
        <div>登録済みのファイル</div>
        <div v-for="(file, index) in fileData.dbFiles" :key="`file_data_${index}`">
          <div v-show="file.do_delete_file === false">
            <b-link
              @click="downloadFile(fileData.dbAssetId, file.dbFileName, type)"
              class="mb-1">{{file.dbFileName}}</b-link>
            <img
              @click="regFileDeleteBtn(index)"
              src="@/assets/svg/close_small.svg"
              class="ml-1 tdDeleteBtn">
          </div>

        </div>

      </span>
    </b-input-group>
  </div>
</template>

<script>


export default {
  name: 'cmsFromFile',
  components: {
  },
  props: {
    errors: {},
    fileKey: {},
    fileData: { type: Object, default: () => {} },
    hasError: {},
    downloadFile: { type: Function },
    noInput: { type: Boolean, default: true },
    type: { type: String, default: '' },
    downloadApploadedFile: { type: Function },
  },
  data() {
    return {
      isDragging: false,
    };
  },
  methods: {
    toggleDrag(event, isDragging) {
      this.isDragging = isDragging;
    },
    onDrop(event) {
      this.isDragging = false;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時処理を中止
      if (fileList.length === 0) {
        return;
      }
      if (this.noInput) {
        return;
      }
      const upFiles = fileList;
      const param = {
        uploadedFileKey: this.fileKey,
        uploadedFiles: upFiles,
      };
      this.$emit('kickUpload', param);
    },
    upload(event) {
      if (event.target.files.length === 0) {
        return;
      }
      if (this.noInput) {
        return;
      }
      const upFiles = event.target.files;
      const param = {
        uploadedFileKey: this.fileKey,
        uploadedFiles: upFiles,
      };
      this.$emit('kickUpload', param);
    },
    kickDeleteFile(index) {
      const param = {
        uploadedFileKey: this.fileKey,
        targetIndex: index,
      };
      this.$emit('kickDeleteFile', param);
      document.getElementById(this.fileKey).value = '';
    },
    regFileDeleteBtn(index) {
      const param = {
        uploadedFileKey: this.fileKey,
        delTargetKey: index,
      };
      this.$emit('delRegFile', param);
    },
  },
  computed: {
    showRegedFileList() {
      if (Object.keys(this.fileData.dbFiles).length === 0) {
        return false;
      }
      const noneDoDeleteFile = Object.keys(this.fileData.dbFiles).filter((key) => {
        return this.fileData.dbFiles[key].do_delete_file === false;
      });
      return Object.keys(noneDoDeleteFile).length > 0;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .link-file-name{
    margin-left: 5px;
  }
  .opacity03 {
    opacity: 0.3;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
    margin-left: 265px;
  }

  .addBtn {
    color:#293CB0 !important;
    border-color: #293CB0 !important;
    border-radius: 30px;
    width: 200px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .drop {
    height: 120px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }

  .dragText {
    color: #BBB;
  }

  .fileText {
    color: #0A8EA7;
  }

  .fileNotation {
    color: #BBB;
  }

  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }

  .is-dragging {
    color: #6E6E6E;
  }

  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }

  .fileName {
    margin-top: 16px;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }
</style>
