<template>
  <perfect-scrollbar class="p-4 ContentsBox">
    <div class="my-2 ml-1 error" v-if="evalHasStarted">
      審査開始後は審査項目の変更はできません。
    </div>
    <div>
      <p class="field-name">評価項目</p>
      <div>
        <div
          class="inlineBlock name-wrap">
          <span class="fw-3 ml-2">項目名</span>
        </div>
        <div
          class="inlineBlock how-wrap">
          <span class="fw-3 ml-2">評価方法</span>
        </div>
        <div
          class="inlineBlock param-wrap">
          <span class="fw-3 ml-2 param-head">設定値</span>
        </div>
        <div class="inlineBlock close-wrap">
          <span class="fw-3">削除</span>
        </div>
      </div>
    </div>
    <div
      v-for="(evaluation, index) in evaluations" :key="`evaluation_${index}`"
      class="my-3">
      <div>
        <div
          class="inlineBlock name-wrap">
          <b-form-input
            :class="[
              hasErr(evalErrors[index], 'name') ? 'errItem' : '',
            ]"
            maxlength="50"
            :disabled="noInput || evalHasStarted"
            @input="updateEvalName($event, index)"
            :value="evaluation.name"/>
        </div>
        <div
          class="inlineBlock how-wrap">
          <b-form-select
            :class="[
              hasErr(evalErrors[index], 'type') ? 'errItem' : '',
            ]"
            :disabled="noInput || evalHasStarted"
            :options="evalOptions"
            @change="updateEvalType($event, index)"
            :value="evaluation.type">
            <template v-slot:first>
              <option :value="null">-- 選択してください。 --</option>
            </template>
          </b-form-select>
        </div>
        <div
          class="inlineBlock param-wrap">
          <div
            v-if="labelText(evaluation.type)"
            class="inlineBlock param-label text-right">
            {{labelText(evaluation.type)}}
          </div>
          <div
            v-if="labelText(evaluation.type)"
            class="inlineBlock">
            <b-form-input
              :class="[
                'param-input',
                hasErr(evalErrors[index], 'param') ? 'errItem' : '',
              ]"
              :disabled="noInput || evalHasStarted"
              @input="updateEvalParam($event, index)"
              :value="evaluation.param"/>
          </div>
        </div>
        <div class="inlineBlock close-wrap">
          <img
            v-if="!evalHasStarted"
            @click="removeEval(index)"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </div>
      <div>
        <p
           v-for="(msg, key) in evalErrors[index]" :key="`evalErrors${key}`"
          class="mb-0 error">
          {{msg}}
        </p>
      </div>
    </div>
    <div class="flex flexRight mt-3">
      <b-button
        v-if="!evalHasStarted"
        @click="addEvaluation"
        class="addBtn"
        variant="outline-primary">
        <img class="plus" src="@/assets/svg/plus.svg">
        入力欄を追加
      </b-button>
    </div>
    <div v-if="hasScore">
      <div>
        <hr>
        <p class="field-name">基準件数</p>
        <div>
          <div
            class="inlineBlock criterion-score-wrap">
            <span class="fw-3 ml-2">点数</span>
          </div>
          <div
            class="inlineBlock criterion-num-wrap">
            <span class="fw-3 ml-2">基準件数</span>
          </div>
          <div class="inlineBlock close-wrap">
            <span class="fw-3">削除</span>
          </div>
        </div>
        <div
          class="my-3"
          v-for="(criterion, index) in criteria" :key="`criterion_${index}`">
          <div>
            <div
              class="inlineBlock criterion-score-wrap">
              <b-input-group>
              <b-form-input
                :class="[
                  hasErr(criteriaErrors[index], 'min_score') ? 'errItem' : '',
                ]"
                maxlength="3"
                :disabled="noInput"
                @input="updateCriterionMin($event, index)"
                :value="criterion.min_score"/>
                <b-input-group-append
                  class="mx-1 flex flexMiddle">
                  点 ~
                </b-input-group-append>
              <b-form-input
                :class="[
                  hasErr(criteriaErrors[index], 'max_score') ? 'errItem' : '',
                ]"
                maxlength="3"
                :disabled="noInput"
                @input="updateCriterionMax($event, index)"
                :value="criterion.max_score"/>
                <b-input-group-append
                  class="mx-1 flex flexMiddle">
                  点
                </b-input-group-append>
              </b-input-group>
            </div>
            <div
              class="inlineBlock criterion-num-wrap">
              <b-input-group>
                <b-form-input
                  :class="[
                    hasErr(criteriaErrors[index], 'num') ? 'errItem' : '',
                  ]"
                  maxlength="3"
                  :disabled="noInput"
                  @input="updateCriterionNum($event, index)"
                  :value="criterion.num"/>
                <b-input-group-append
                  class="mx-1 flex flexMiddle">
                  件
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="inlineBlock close-wrap">
              <img
                @click="removeCriterion(index)"
                src="@/assets/svg/close.svg"
                class="tdDeleteBtn">
            </div>
          </div>
          <div>
            <p
              v-for="(msg, key) in criteriaErrors[index]" :key="`criteriaErrors${key}`"
              class="mb-0 error">
              {{msg}}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flexRight mt-3">
        <b-button
          @click="addCriterion"
          class="addBtn"
          variant="outline-primary">
          <img class="plus" src="@/assets/svg/plus.svg">
          入力欄を追加
        </b-button>
      </div>
    </div>
  </perfect-scrollbar>
</template>
<script>
import moment from 'moment';
import CONST_EVAL from '@/constants/evaluation';

export default {
  name: 'cmsEvaluation',
  components: {
  },
  props: {
    evalErrors: { type: Object, default: () => {} },
    criteriaErrors: { type: Object, default: () => {} },
    noInput: { type: Boolean, default: true },
    now: {},
  },
  data() {
    return {
    };
  },
  methods: {
    addEvaluation() {
      if (this.evaluations.length >= 17 || this.noInput) {
        return;
      }
      this.$store.commit('cmsForm/addEvaluation');
    },
    removeEval(index) {
      this.$store.commit('cmsForm/removeEvaluation', index);
    },
    updateEvalName(value, index) {
      this.$store.commit('cmsForm/updateEvalName', { value, index });
    },
    updateEvalType(value, index) {
      this.$store.commit('cmsForm/updateEvalType', { value, index });
      this.$store.commit('cmsForm/updateEvalParam', { value: '', index });
    },
    updateEvalParam(value, index) {
      this.$store.commit('cmsForm/updateEvalParam', { value, index });
    },
    addCriterion() {
      this.$store.commit('cmsForm/addCriterion');
    },
    removeCriterion(index) {
      this.$store.commit('cmsForm/removeCriterion', index);
    },
    updateCriterionMin(value, index) {
      this.$store.commit('cmsForm/updateCriterionMin', { value, index });
    },
    updateCriterionMax(value, index) {
      this.$store.commit('cmsForm/updateCriterionMax', { value, index });
    },
    updateCriterionNum(value, index) {
      this.$store.commit('cmsForm/updateCriterionNum', { value, index });
    },
  },
  computed: {
    evaluations() {
      return this.$store.state.cmsForm.evaluations;
    },
    evalOptions() {
      return CONST_EVAL.EVALUATION_TYPE_OPTION;
    },
    criteria() {
      return this.$store.state.cmsForm.criteria;
    },
    labelText() {
      const types = CONST_EVAL.EVALUATION_TYPE;
      return (type) => {
        if (Number(type) === types.SCORE || Number(type) === types.ZAIDAN) {
          return '最高得点';
        }
        if (Number(type) === types.COMMENT) {
          return '最大字数';
        }
        if (Number(type) === types.RANK) {
          return '入力順位';
        }
        if (Number(type) === types.ADJUSTMENT) {
          return '＋－';
        }
        return false;
      };
    },
    evalHasStarted() {
      const stagesDate = this.$store.state.cmsForm.stages;
      let result = false;
      // 1つでも審査が始まっているかどうか
      stagesDate.forEach((stage) => {
        if (stage.from !== null && moment(stage.from).isSameOrBefore(this.now)) {
          result = true;
        }
      });
      return result;
    },
    hasErr() {
      return (msgList, key) => {
        if (!msgList) {
          return false;
        }
        return msgList && msgList[key];
      };
    },
    // 点数評価が一つでもあるかどうか
    hasScore() {
      const types = CONST_EVAL.EVALUATION_TYPE;
      const result = this.evaluations.some((evalItem) => {
        return Number(evalItem.type) === types.SCORE;
      });
      return result;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .errItem {
    border:solid 1px #dc3545 !important;
  }

  .opacity03 {
    opacity: 0.3;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .badgeWrap {
    margin-bottom: 10px;
  }

  .require-label {
    margin-left: 40px;
  }

  .error {
    color: #dc3545;
    margin-top: 5px;
    margin-left: 15px;
  }

  .addBtn {
    color:#293CB0 !important;
    border-color: #293CB0 !important;
    border-radius: 30px;
    width: 200px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .name-wrap {
    width: 300px;
    margin-right: 20px;
  }
  .how-wrap {
    width: 250px;
    margin-right: 20px;
  }
  .param-wrap {
    width: 280px;
    margin-right: 20px;
  }
  .param-head {
    padding-left: 85px;
  }
  .param-label {
    width: 100px;
    margin-right: 20px;
  }
  .param-input {
    width: 150px;
  }
  .close-wrap {
    width: 120px;
  }

  input, select {
    height: 50px;
  }
  .close-td>img {
    position: relative;
    top: 10px;
  }

  .roop-wrap {
    width: 1030px;
  }

  .field-name {
    font-size: 16px;
    font-weight: bold;
  }

  .criterion-score-wrap {
    width: 400px;
    margin-right: 20px;
  }
  .criterion-num-wrap {
    width: 200px;
    margin-right: 20px;
  }
</style>
