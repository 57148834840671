<template>
  <perfect-scrollbar class="p-4 ContentsBox">
    <div
      v-for="(seriesFile, index) in seriesFiles" :key="`file_${index}`">
      <b-row class="mt-2">
        <b-col sm="3" class="mt-4 label">
          <label :class="[
            'labelText bold require-label',
            noInput ? 'opacity03' : '',
            ]">{{seriesFile.label}}</label>
        </b-col>
        <b-col sm="9">
          <v-file-form
            :fileKey="seriesFile.fileKey"
            :fileData.sync="files[seriesFile.fileKey]"
            :hasError="fileErrors[seriesFile.fileKey] && fileErrors.fileAppForm.length>0"
            @kickUpload="upload"
            :downloadFile="downloadFile"
            :downloadApploadedFile="downloadApploadedFile"
            :noInput='noInput'
            @kickDeleteFile="kickDeleteFile"
            @delRegFile="delRegFile"
            :type="'apptype'"/>
        </b-col>
      </b-row>
      <p
        v-for="(msg, index) in fileErrors[seriesFile.fileKey]" :key="`fileErrors${index}`"
        class="mb-0 error">
        {{msg}}
      </p>
    </div>

    <div
      v-for="(changePlanFile, index) in seriesChangePlanFiles" :key="`changePlanFile_${index}`">
      <b-row class="mt-2">
        <b-col sm="3" class="mt-4 label">
          <label :class="[
            'labelText bold require-label',
            noInput ? 'opacity03' : '',
            ]">{{changePlanFile.label}}</label>
        </b-col>
        <b-col sm="9">
          <v-file-form
            :fileKey="changePlanFile.fileKey"
            :fileData.sync="changePlanFiles[changePlanFile.fileKey]"
            :hasError="fileErrors[changePlanFile.fileKey] && fileErrors.fileAppForm.length>0"
            @kickUpload="uploadChangePlanFile"
            :downloadFile="downloadFile"
            :downloadApploadedFile="downloadApploadedFile"
            :noInput='noInput'
            @kickDeleteFile="kickDeleteChangePlanFile"
            @delRegFile="delRegChangePlanFile"
            :type="'changePlan'"/>
        </b-col>
      </b-row>
      <p
        v-for="(msg, index) in fileErrors[changePlanFile.fileKey]" :key="`changePlanFileErrors${index}`"
        class="mb-0 error">
        {{msg}}
      </p>
    </div>

    <div
      v-for="(requiredDocFile, index) in seriesRequiredDocFiles" :key="`requiredDocFile_${index}`">
      <b-row class="mt-2">
        <b-col sm="3" class="mt-4 label">
          <label :class="[
            'labelText bold require-label',
            noInput ? 'opacity03' : '',
            ]">{{requiredDocFile.label}}</label>
        </b-col>
        <b-col sm="9">
          <v-file-form
            :fileKey="requiredDocFile.fileKey"
            :fileData.sync="requiredDocFiles[requiredDocFile.fileKey]"
            :hasError="fileErrors[requiredDocFile.fileKey] && fileErrors.fileAppForm.length>0"
            @kickUpload="uploadRequiredDocFile"
            :downloadFile="downloadFile"
            :downloadApploadedFile="downloadApploadedFile"
            :noInput='noInput'
            @kickDeleteFile="kickDeleteRequiredDocFile"
            @delRegFile="delRegRequiredDocFile"
            :type="'requiredDoctype'"/>
        </b-col>
      </b-row>
      <p
        v-for="(msg, index) in fileErrors[requiredDocFile.fileKey]" :key="`requiredDocFileErrors${index}`"
        class="mb-0 error">
        {{msg}}
      </p>
    </div>
    <div
      v-show="selectedSeries.has_interim_report"
      v-for="(interimFile, index) in seriesInterimFiles" :key="`interimRepFile_${index}`">
      <b-row class="mt-2">
        <b-col sm="3" class="mt-4 label">
          <label :class="[
            'labelText bold require-label',
            noInput ? 'opacity03' : '',
            ]">{{interimFile.label}}</label>
        </b-col>
        <b-col sm="9">
          <v-file-form
            :fileKey="interimFile.fileKey"
            :fileData.sync="interimRepFiles[interimFile.fileKey]"
            :hasError="fileErrors[interimFile.fileKey] && fileErrors.fileAppForm.length>0"
            @kickUpload="uploadInterimRepFile"
            :downloadFile="downloadFile"
            :downloadApploadedFile="downloadApploadedFile"
            :noInput='noInput'
            @kickDeleteFile="kickDeleteInterimRepFile"
            @delRegFile="delRegInterimRepFile"
            :type="'interimtype'"/>
        </b-col>
      </b-row>
      <p
        v-for="(msg, index) in fileErrors[interimFile.fileKey]" :key="`interimRepFileErrors${index}`"
        class="mb-0 error">
        {{msg}}
      </p>
    </div>

    <div
      v-for="(reportFile, index) in seriesReportFiles" :key="`reportFile_${index}`">
      <b-row class="mt-2">
        <b-col sm="3" class="mt-4 label">
          <label :class="[
            'labelText bold require-label',
            noInput ? 'opacity03' : '',
            ]">{{reportFile.label}}</label>
        </b-col>
        <b-col sm="9">
          <v-file-form
            :fileKey="reportFile.fileKey"
            :fileData.sync="reportFiles[reportFile.fileKey]"
            :hasError="fileErrors[reportFile.fileKey] && fileErrors.fileAppForm.length>0"
            @kickUpload="uploadReportFile"
            :downloadFile="downloadFile"
            :downloadApploadedFile="downloadApploadedFile"
            :noInput='noInput'
            @kickDeleteFile="kickDeleteReportFile"
            @delRegFile="delRegReportFile"
            :type="'reptype'"/>
        </b-col>
      </b-row>
      <p
        v-for="(msg, index) in fileErrors[reportFile.fileKey]" :key="`reportFileErrors${index}`"
        class="mb-0 error">
        {{msg}}
      </p>
    </div>
  </perfect-scrollbar>
</template>

<script>
import FileForm from '@/components/cms/form/parts/File.vue';
import CONST_APPTYPE from '@/constants/apptypes';

export default {
  name: 'cmsFromFiles',
  components: {
    'v-file-form': FileForm,
  },
  props: {
    fileErrors: { type: [Object, Array], default: () => {} },
    downloadFile: { type: Function },
    noInput: { type: Boolean, default: true },
    series: {},
    selectedSeries: { type: Object, default: () => {} },
    downloadApploadedFile: { type: Function },
  },
  data() {
    return {
    };
  },
  methods: {
    upload(param) {
      this.$store.commit('cmsForm/uploadFile', param);
    },
    uploadInterimRepFile(param) {
      this.$store.commit('cmsForm/uploadInterimRepFile', param);
    },
    uploadReportFile(param) {
      this.$store.commit('cmsForm/uploadReportFile', param);
    },
    uploadRequiredDocFile(param) {
      this.$store.commit('cmsForm/uploadRequiredDocFile', param);
    },
    uploadChangePlanFile(param) {
      this.$store.commit('cmsForm/uploadChangePlanFile', param);
    },
    delRegFile(param) {
      this.$store.commit('cmsForm/delRegFile', param);
    },
    delRegInterimRepFile(param) {
      this.$store.commit('cmsForm/delRegInterimRepFile', param);
    },
    delRegReportFile(param) {
      this.$store.commit('cmsForm/delRegReportFile', param);
    },
    delRegRequiredDocFile(param) {
      this.$store.commit('cmsForm/delRegRequiredDocFile', param);
    },
    delRegChangePlanFile(param) {
      this.$store.commit('cmsForm/delRegChangePlanFile', param);
    },
    kickDeleteFile(param) {
      this.$store.commit('cmsForm/kickDeleteFile', param);
    },
    kickDeleteChangePlanFile(param) {
      this.$store.commit('cmsForm/kickDeleteChangePlanFile', param);
    },
    kickDeleteRequiredDocFile(param) {
      this.$store.commit('cmsForm/kickDeleteRequiredDocFile', param);
    },
    kickDeleteInterimRepFile(param) {
      this.$store.commit('cmsForm/kickDeleteInterimRepFile', param);
    },
    kickDeleteReportFile(param) {
      this.$store.commit('cmsForm/kickDeleteReportFile', param);
    },
  },
  computed: {
    hasErr() {
      return (key) => {
        if (!this.errors[key]) {
          return false;
        }
        return true;
      };
    },
    fileAppForm() {
      return this.$store.state.cmsForm.fileAppForm;
    },
    fileAcceptance() {
      return this.$store.state.cmsForm.fileAcceptance;
    },
    fileCostList() {
      return this.$store.state.cmsForm.fileCostList;
    },
    fileReport() {
      return this.$store.state.cmsForm.fileReport;
    },
    fileCostReport() {
      return this.$store.state.cmsForm.fileCostReport;
    },
    files() {
      return this.$store.state.cmsForm.files;
    },
    interimRepFiles() {
      return this.$store.state.cmsForm.interimRepFiles;
    },
    reportFiles() {
      return this.$store.state.cmsForm.reportFiles;
    },
    requiredDocFiles() {
      return this.$store.state.cmsForm.requiredDocFiles;
    },
    changePlanFiles() {
      return this.$store.state.cmsForm.changePlanFiles;
    },
    realFiles() {
      return this.$store.state.cmsForm.realFiles;
    },
    // 申請プログラム種別別のファイル
    seriesFiles() {
      if (!this.series) {
        return {};
      }
      return CONST_APPTYPE.SERIES_APP_FILES[this.series];
    },
    seriesInterimFiles() {
      if (!this.series) {
        return {};
      }
      return CONST_APPTYPE.SERIES_INTERIM_FILES[this.series];
    },
    seriesReportFiles() {
      if (!this.series) {
        return {};
      }
      return CONST_APPTYPE.SERIES_REPORT_FILES[this.series];
    },
    seriesRequiredDocFiles() {
      if (!this.series) {
        return {};
      }
      return CONST_APPTYPE.SERIES_REQUIRED_DOC_FILES[this.series];
    },
    seriesChangePlanFiles() {
      if (!this.series) {
        return {};
      }
      return CONST_APPTYPE.SERIES_CHANGE_PLAN_FILES[this.series];
    },
    apptype() {
      return this.$store.state.cmsForm.apptype;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
    margin-left: 265px;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }

  .require-label {
    margin-left: 40px;
  }

  .badgeWrap {
    margin-bottom: 10px;
  }
</style>
