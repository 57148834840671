<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">申請・評価設定</p>
        <hr class="title">
      </div>
      <div class="topBtnWrap">
        <b-button
          variant="primary"
          :disabled="noYearInput"
          :to="`/cms/form/period/${search.year}`"
          class="btn btn-lg bold">期間一括設定</b-button>
      </div>
      <div id="serch-wrap" class="">
        <div class="bold">絞り込み条件</div>
        <div class="search-area flex flexCenter">

          <div class="long-input inlineBlock search-space">
            <div>年度</div>
            <div>
              <b-form-select
                :options="yearList"
                v-model="search.year"
                @input="fetchFormData">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="long-input inlineBlock search-space">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                :options="seriesList"
                v-model="search.series"
                @input="fetchFormData">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="noInput"
        class="contentsWidth mx-auto">
        <p class="notice text-center">年度と助成プログラムを選択してください。</p>
      </div>

      <b-tabs
        class="gas-tab"
        nav-wrapper-class="gas-tab-wrap">
        <b-tab
          title-link-class="px-5">
          <v-date-form
            :errors.sync="errors"
            :now.sync="now"
            :noInput.sync="noInput"
            :isInterimApp="isInterimApp"/>
          <template v-slot:title>
            <span>期間設定</span>
            <img
              v-show="Object.keys(errors).length > 0"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
        </b-tab>
        <b-tab
          v-if="seriesFiles"
          title-link-class="px-4">
          <template v-slot:title>
            <span>ファイル設定</span>
            <img
              v-show="Object.keys(fileErrors).length > 0"
              class="errorMark"
              src="@/assets/svg/error.svg"/>
          </template>
          <v-files-form
            :series="search.series"
            :fileErrors="fileErrors"
            :downloadFile="downloadFile"
            :downloadApploadedFile="downloadApploadedFile"
            :noInput.sync="noInput"
            :selectedSeries.sync="selectedSeries"/>
        </b-tab>
        <b-tab
          title-link-class="px-4">
          <template v-slot:title>
            <span>評価項目設定</span>
            <img
              v-show="Object.keys(evalErrors).length > 0"
              class="errorMark"
              src="@/assets/svg/error.svg"/>
          </template>
          <v-evaluation-form
            :now.sync="now"
            :evalErrors="evalErrors"
            :criteriaErrors="criteriaErrors"
            :noInput.sync="noInput"/>
        </b-tab>
      </b-tabs>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
          class="btn btn-lg bold mr-4"
          to="/cms/top">トップに戻る</b-button>
        <b-button
          @click="save"
          variant="primary"
          :disabled="noInput"
          class="btn btn-lg bold">保存</b-button>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import DateForm from '@/components/cms/form/Date.vue';
import FilesForm from '@/components/cms/form/Files.vue';
import Evaluation from '@/components/cms/form/Evaluation.vue';
import CONST_APPTYPE from '@/constants/apptypes';

export default {
  name: 'CmsFormEditView',
  components: {
    // eslint-disable-next-line
    'v-date-form': DateForm,
    // eslint-disable-next-line
    'v-files-form': FilesForm,
    // eslint-disable-next-line
    'v-evaluation-form': Evaluation,
  },
  data() {
    return {
      search: {
        year: null,
        series: null,
      },
      now: moment(),
      dbYearList: [],
      dbSeriesList: [],
      errors: {},
      fileErrors: {},
      evalErrors: {},
      criteriaErrors: {},
      isInterimApp: true,
    };
  },
  computed: {
    noInput() {
      return this.search.year === null || this.search.series === null;
    },
    noYearInput() {
      return this.search.year === null;
    },
    apptypeId() {
      return this.$store.state.cmsForm.apptypeId;
    },
    apptype() {
      return this.$store.state.cmsForm.apptype;
    },
    stages() {
      return this.$store.state.cmsForm.stages;
    },
    yearList() {
      const yearList = this.dbYearList.slice();
      const nowYear = Number(moment().format('YYYY')) + 1;
      if (yearList.indexOf(nowYear) === -1) {
        yearList.unshift(nowYear);
      }
      return yearList;
    },
    seriesList() {
      const filterSeriesList = this.dbSeriesList.filter((data) => {
        return (this.search.year !== null && this.search.year < 2025) || data.id !== 9;
      });
      return filterSeriesList.map((series) => {
        if (this.search.year !== null) {
          if (this.search.year < 2025 && series.id === 2) {
            return { value: series.id, text: '調査研究助成' };
          }
          if (this.search.year < 2025 && series.id === 8) {
            return { value: series.id, text: '目的型調査研究助成' };
          }
        }

        return { value: series.id, text: series.name };
      });
    },
    selectedSeries() {
      return this.dbSeriesList.find((series) => {
        return series.id === this.search.series;
      });
    },
    fileAppForm() {
      return this.$store.state.cmsForm.fileAppForm;
    },
    fileAcceptance() {
      return this.$store.state.cmsForm.fileAcceptance;
    },
    fileCostList() {
      return this.$store.state.cmsForm.fileCostList;
    },
    fileReport() {
      return this.$store.state.cmsForm.fileReport;
    },
    fileCostReport() {
      return this.$store.state.cmsForm.fileCostReport;
    },
    files() {
      return this.$store.state.cmsForm.files;
    },
    interimRepFiles() {
      return this.$store.state.cmsForm.interimRepFiles;
    },
    reportFiles() {
      return this.$store.state.cmsForm.reportFiles;
    },
    requiredDocFiles() {
      return this.$store.state.cmsForm.requiredDocFiles;
    },
    changePlanFiles() {
      return this.$store.state.cmsForm.changePlanFiles;
    },
    realFiles() {
      return this.$store.state.cmsForm.realFiles;
    },
    evaluations() {
      return this.$store.state.cmsForm.evaluations;
    },
    criteria() {
      return this.$store.state.cmsForm.criteria;
    },
    // 申請プログラム種別別のファイル
    seriesFiles() {
      if (!this.search.series) {
        return {};
      }
      return CONST_APPTYPE.SERIES_APP_FILES[this.search.series];
    },
    upFileKeys() {
      return this.$store.state.cmsForm.upFileKeys;
    },
    tempUpFileList() {
      return this.$store.state.cmsForm.tempUpFileList;
    },
  },
  methods: {
    init() {
      this.errors = {};
      this.fileErrors = {};
      this.evalErrors = {};
      this.criteriaErrors = {};
      this.now = moment();
    },
    async initFetch() {
      const response = await api.sendForm('/api/cms/form/init')
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (response === false) {
        console.error('error');
      }
      this.dbYearList = response.data.yearList;
      this.dbSeriesList = response.data.seriesList;
    },
    async fetchFormData() {
      if (this.search.year >= 2025 && this.search.series === 9) {
        this.search.series = null;
      }
      if (this.search.year === null || this.search.series === null) {
        this.init();
        this.$store.commit('cmsForm/init');
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const fileParam = {
        defineFiles: CONST_APPTYPE,
        seriesId: this.search.series,
      };
      this.$store.commit('cmsForm/setInitFiles', fileParam);
      this.init();
      const response = await api.send('/api/cms/form/edit', this.search)
        .catch((error) => {
          console.log(error);
          return false;
        });
      this.$store.commit('cmsForm/init');
      if (response === false) {
        await this.alert('データの取得に失敗しました。');
        this.$store.dispatch('page/offWaiting');
        return;
      }
      this.$store.commit('cmsForm/setSeries', this.search.series);
      this.$store.commit('cmsForm/setDbData', response.data.formData);
      const fileData = {
        app: response.data.fileData,
        interimRep: response.data.interimRepFileData,
        report: response.data.reportFileData,
        requiredDoc: response.data.requiredDocFileData,
        changePlan: response.data.changePlanFileData,
      };
      this.isInterimApp = response.data.isInterimApp;
      this.$store.commit('cmsForm/setDbFileData', fileData);
      this.$store.commit('cmsForm/setDbEvalItems', response.data.evalItems);
      this.$store.commit('cmsForm/setDbCriteria', response.data.criteria);
      this.$store.commit('cmsForm/setOldYearData', response.data.backYearData);
      this.$store.dispatch('page/offWaiting');
    },
    async save() {
      this.$store.dispatch('page/onWaiting');
      this.init();
      const base = Object.assign(this.search);
      base.apptypeId = this.apptypeId;
      const params = {
        base: JSON.stringify(base),
        apptype: JSON.stringify(this.apptype),
        stages: JSON.stringify(this.stages),
        files: JSON.stringify(this.files),
        interimRepFiles: JSON.stringify(this.interimRepFiles),
        reportFiles: JSON.stringify(this.reportFiles),
        requiredDocFiles: JSON.stringify(this.requiredDocFiles),
        changePlanFiles: JSON.stringify(this.changePlanFiles),
        evaluations: (this.evaluations.length === 1 && this.evaluations[0].name === '' && this.evaluations[0].param === '' && this.evaluations[0].type === null) ? JSON.stringify([]) : JSON.stringify(this.evaluations),
        criteria: JSON.stringify(this.criteria),
      };
      Object.keys(this.realFiles).forEach((key) => {
        if (this.realFiles[key]) {
          Object.keys(this.realFiles[key]).forEach((key2) => {
            params[`real_${key}[${key2}]`] = this.realFiles[key][key2];
          });
        }
      });
      const response = await api.sendForm('/api/cms/form/save', params)
        .catch(async (error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.messages;
            this.fileErrors = error.response.data.fileMessages;
            this.evalErrors = error.response.data.evalMessages;
            this.criteriaErrors = error.response.data.criteriaMessages;
            await this.alert('入力内容に誤りがありました。');
          } else {
            await this.alert('保存に失敗しました。');
          }
          return false;
        });
      if (response === false) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      Object.keys(this.files).forEach((key) => {
        this.$store.commit('cmsForm/setRegEndFile', this.files[key]);
      });
      Object.keys(this.interimRepFiles).forEach((key) => {
        this.$store.commit('cmsForm/setRegEndInterimRepFile', this.interimRepFiles[key]);
      });
      Object.keys(this.reportFiles).forEach((key) => {
        this.$store.commit('cmsForm/setRegEndReportFile', this.reportFiles[key]);
      });
      Object.keys(this.requiredDocFiles).forEach((key) => {
        this.$store.commit('cmsForm/setRegEndRequiredDocFile', this.requiredDocFiles[key]);
      });
      Object.keys(this.changePlanFiles).forEach((key) => {
        this.$store.commit('cmsForm/setRegEndChangePlanFile', this.changePlanFiles[key]);
      });
      const fileEle = document.querySelectorAll('input[type="file"]');
      for (let i = 0; i < fileEle.length; i += 1) {
        fileEle[i].value = '';
      }
      await this.alert('保存しました。', false);
      this.$store.dispatch('page/offWaiting');
      // 絶対に再読み込みが必要。新規登録の場合、登録後に編集登録のトリガーになるapptypeIDを取得する必要があるので。
      await this.fetchFormData();
    },
    async downloadFile(assetId, fileName, type) {
      const param = {
        assetId,
        type,
        fileName,
      };
      const url = '/api/cms/form/download';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async downloadApploadedFile(fileKeyName, index, fileName) {
      const fileKey = this.upFileKeys[fileKeyName][index];
      const url = '/api/cms/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[fileKeyName] || !this.tempUpFileList[fileKeyName][fileKey]) {
        const itemNumber = `${fileKeyName}-${fileKey}`;
        const tempUpUrl = '/api/cms/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        tempParam.file = this.realFiles[fileKeyName][index];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        const saveTempResponse = {
          target: fileKeyName,
          subKey: fileKey,
          path: tempResponse.data.path,
        };
        this.$store.commit('cmsForm/saveTempPath', saveTempResponse);
      }
      const path = this.tempUpFileList[fileKeyName][fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('cmsForm/init');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  #serch-wrap input, #serch-wrap select {
    height: 50px;
  }

  .year-select {
    height: 40px;
    width: 120px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .search-space {
    margin-right: 15px;
  }

  .long-input {
    width: 520px;
  }

  .notice {
    color: #dc3545;
  }
  .errItem input{
    border:solid 1px #dc3545;
  }
  .error{
    font-size: 12.8px;
    color: #dc3545;
    display: block;
    width: 582px;
    margin-left: 15px;
    margin-top: 3px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .topBtnWrap {
    display: flex;
    justify-content: end;
  }
</style>
