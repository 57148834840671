var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-input-group',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileData.uploadedFileNames.length > 0),expression:"fileData.uploadedFileNames.length > 0"}],staticClass:"mb-2 fileName"},[_vm._v(" ファイル名："),_vm._l((_vm.fileData.uploadedFileNames),function(fileName,index){return _c('span',{key:index},[(index !== 0)?_c('span',[_vm._v(", ")]):_vm._e(),_c('b-link',{staticClass:"link-file-name",on:{"click":function($event){return _vm.downloadApploadedFile(_vm.fileData.uploadedFileKey ,index, fileName)}}},[_vm._v(_vm._s(fileName))]),_c('img',{staticClass:"tdDeleteBtn",attrs:{"src":require("@/assets/svg/close_small.svg")},on:{"click":function($event){return _vm.kickDeleteFile(index)}}})],1)})],2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileData.uploadedFileNames.length === 0),expression:"fileData.uploadedFileNames.length === 0"}],staticClass:"mb-2 fileName"},[_vm._v(" ファイル名：ファイルが選択されていません。 ")])]),_c('b-input-group',[_c('div',{class:[
        _vm.isDragging ? 'is-dragging' : '',
        _vm.hasError ? 'errItem' : '',
        'drop', 'flex', 'flexMiddle', 'flexCenter'
      ],on:{"dragover":function($event){$event.preventDefault();return _vm.toggleDrag($event, true)},"dragleave":function($event){$event.preventDefault();return _vm.toggleDrag($event, false)},"drop":function($event){$event.preventDefault();return _vm.onDrop($event)}}},[_c('span',[_c('span',{class:[
          _vm.isDragging ? 'is-dragging' : '',
          'dragText', 'fw-6'
          ]},[_vm._v("ここにファイルをドラッグ＆ドロップ または")]),(!_vm.noInput)?_c('label',{staticClass:"fileText fw-6 selectFile",attrs:{"for":_vm.fileKey}},[_vm._v("［ ファイルを選択 ］")]):_vm._e(),(_vm.noInput)?_c('label',{staticClass:"fileText fw-6 selectFile"},[_vm._v("［ ファイルを選択 ］")]):_vm._e(),_c('p',{class:[
            _vm.isDragging ? 'is-dragging' : '',
            'fileNotation', 'mb-0'
          ]},[_vm._v(" （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます） ")]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":_vm.fileKey,"multiple":""},on:{"change":function($event){return _vm.upload($event)}}})])])]),_c('b-input-group',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRegedFileList),expression:"showRegedFileList"}],staticClass:"mb-2 fileName"},[_c('div',[_vm._v("登録済みのファイル")]),_vm._l((_vm.fileData.dbFiles),function(file,index){return _c('div',{key:`file_data_${index}`},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(file.do_delete_file === false),expression:"file.do_delete_file === false"}]},[_c('b-link',{staticClass:"mb-1",on:{"click":function($event){return _vm.downloadFile(_vm.fileData.dbAssetId, file.dbFileName, _vm.type)}}},[_vm._v(_vm._s(file.dbFileName))]),_c('img',{staticClass:"ml-1 tdDeleteBtn",attrs:{"src":require("@/assets/svg/close_small.svg")},on:{"click":function($event){return _vm.regFileDeleteBtn(index)}}})],1)])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }