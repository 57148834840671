var render = function render(){var _vm=this,_c=_vm._self._c;return _c('perfect-scrollbar',{staticClass:"p-4 ContentsBox"},[_vm._l((_vm.seriesFiles),function(seriesFile,index){return _c('div',{key:`file_${index}`},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('label',{class:[
          'labelText bold require-label',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v(_vm._s(seriesFile.label))])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":seriesFile.fileKey,"fileData":_vm.files[seriesFile.fileKey],"hasError":_vm.fileErrors[seriesFile.fileKey] && _vm.fileErrors.fileAppForm.length>0,"downloadFile":_vm.downloadFile,"downloadApploadedFile":_vm.downloadApploadedFile,"noInput":_vm.noInput,"type":'apptype'},on:{"update:fileData":function($event){return _vm.$set(_vm.files, seriesFile.fileKey, $event)},"update:file-data":function($event){return _vm.$set(_vm.files, seriesFile.fileKey, $event)},"kickUpload":_vm.upload,"kickDeleteFile":_vm.kickDeleteFile,"delRegFile":_vm.delRegFile}})],1)],1),_vm._l((_vm.fileErrors[seriesFile.fileKey]),function(msg,index){return _c('p',{key:`fileErrors${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])})],2)}),_vm._l((_vm.seriesChangePlanFiles),function(changePlanFile,index){return _c('div',{key:`changePlanFile_${index}`},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('label',{class:[
          'labelText bold require-label',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v(_vm._s(changePlanFile.label))])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":changePlanFile.fileKey,"fileData":_vm.changePlanFiles[changePlanFile.fileKey],"hasError":_vm.fileErrors[changePlanFile.fileKey] && _vm.fileErrors.fileAppForm.length>0,"downloadFile":_vm.downloadFile,"downloadApploadedFile":_vm.downloadApploadedFile,"noInput":_vm.noInput,"type":'changePlan'},on:{"update:fileData":function($event){return _vm.$set(_vm.changePlanFiles, changePlanFile.fileKey, $event)},"update:file-data":function($event){return _vm.$set(_vm.changePlanFiles, changePlanFile.fileKey, $event)},"kickUpload":_vm.uploadChangePlanFile,"kickDeleteFile":_vm.kickDeleteChangePlanFile,"delRegFile":_vm.delRegChangePlanFile}})],1)],1),_vm._l((_vm.fileErrors[changePlanFile.fileKey]),function(msg,index){return _c('p',{key:`changePlanFileErrors${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])})],2)}),_vm._l((_vm.seriesRequiredDocFiles),function(requiredDocFile,index){return _c('div',{key:`requiredDocFile_${index}`},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('label',{class:[
          'labelText bold require-label',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v(_vm._s(requiredDocFile.label))])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":requiredDocFile.fileKey,"fileData":_vm.requiredDocFiles[requiredDocFile.fileKey],"hasError":_vm.fileErrors[requiredDocFile.fileKey] && _vm.fileErrors.fileAppForm.length>0,"downloadFile":_vm.downloadFile,"downloadApploadedFile":_vm.downloadApploadedFile,"noInput":_vm.noInput,"type":'requiredDoctype'},on:{"update:fileData":function($event){return _vm.$set(_vm.requiredDocFiles, requiredDocFile.fileKey, $event)},"update:file-data":function($event){return _vm.$set(_vm.requiredDocFiles, requiredDocFile.fileKey, $event)},"kickUpload":_vm.uploadRequiredDocFile,"kickDeleteFile":_vm.kickDeleteRequiredDocFile,"delRegFile":_vm.delRegRequiredDocFile}})],1)],1),_vm._l((_vm.fileErrors[requiredDocFile.fileKey]),function(msg,index){return _c('p',{key:`requiredDocFileErrors${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])})],2)}),_vm._l((_vm.seriesInterimFiles),function(interimFile,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSeries.has_interim_report),expression:"selectedSeries.has_interim_report"}],key:`interimRepFile_${index}`},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('label',{class:[
          'labelText bold require-label',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v(_vm._s(interimFile.label))])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":interimFile.fileKey,"fileData":_vm.interimRepFiles[interimFile.fileKey],"hasError":_vm.fileErrors[interimFile.fileKey] && _vm.fileErrors.fileAppForm.length>0,"downloadFile":_vm.downloadFile,"downloadApploadedFile":_vm.downloadApploadedFile,"noInput":_vm.noInput,"type":'interimtype'},on:{"update:fileData":function($event){return _vm.$set(_vm.interimRepFiles, interimFile.fileKey, $event)},"update:file-data":function($event){return _vm.$set(_vm.interimRepFiles, interimFile.fileKey, $event)},"kickUpload":_vm.uploadInterimRepFile,"kickDeleteFile":_vm.kickDeleteInterimRepFile,"delRegFile":_vm.delRegInterimRepFile}})],1)],1),_vm._l((_vm.fileErrors[interimFile.fileKey]),function(msg,index){return _c('p',{key:`interimRepFileErrors${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])})],2)}),_vm._l((_vm.seriesReportFiles),function(reportFile,index){return _c('div',{key:`reportFile_${index}`},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('label',{class:[
          'labelText bold require-label',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v(_vm._s(reportFile.label))])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":reportFile.fileKey,"fileData":_vm.reportFiles[reportFile.fileKey],"hasError":_vm.fileErrors[reportFile.fileKey] && _vm.fileErrors.fileAppForm.length>0,"downloadFile":_vm.downloadFile,"downloadApploadedFile":_vm.downloadApploadedFile,"noInput":_vm.noInput,"type":'reptype'},on:{"update:fileData":function($event){return _vm.$set(_vm.reportFiles, reportFile.fileKey, $event)},"update:file-data":function($event){return _vm.$set(_vm.reportFiles, reportFile.fileKey, $event)},"kickUpload":_vm.uploadReportFile,"kickDeleteFile":_vm.kickDeleteReportFile,"delRegFile":_vm.delRegReportFile}})],1)],1),_vm._l((_vm.fileErrors[reportFile.fileKey]),function(msg,index){return _c('p',{key:`reportFileErrors${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }