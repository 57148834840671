import { render, staticRenderFns } from "./File.vue?vue&type=template&id=7a0711e3&scoped=true"
import script from "./File.vue?vue&type=script&lang=js"
export * from "./File.vue?vue&type=script&lang=js"
import style0 from "./File.vue?vue&type=style&index=0&id=7a0711e3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0711e3",
  null
  
)

export default component.exports